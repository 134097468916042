import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {
    mails: null,
    mail: null,
    mailCounts: null,
  },
  mutations: {
    updateMails(state, newMails) {
      state.mails = newMails;
    },
    updateMail(state, newMail) {
      state.mail = newMail;
    },
    updateMailCounts(state, newMailCounts) {
      state.mailCounts = newMailCounts;
    }
  },
  actions: {
    async getMailsAction({ commit }, params) {
      const mails = await data.getData(`${API}/mailbox/`, params);
      commit("updateMails", mails);
      return mails
    },
    async getMailAction({ commit }, id) {
      const mail = await data.getData(`${API}/mailbox/detail/${id}`);
      commit("updateMail", mail);
      return mail
    },
    async deleteMailAction({ commit }, params) {
      const mail = await data.putData(`${API}/mailbox/trash/`, params);
      commit("updateMail", mail);
      return mail
    },
    async updateMailAction({ commit }, params) {
      const mail = await data.updateMail(params);
      commit("updateMail", mail);
      return mail
    },
    async sendMailAction({ commit }, params) {
      const mail = await data.putData(`${API}/mailbox/send/`, params);
      commit("updateMail", mail);
      return mail
    },
    async getMailCountsAction({ commit }) {
      console.log('mailCounts')
      const mailCounts = await data.getData(`${API}/mailbox/unread_total`),
        item_mails = Array.isArray(mailCounts) && mailCounts.length > 0 && mailCounts[0] !== undefined ? mailCounts[0].unread_total : 0;
      commit("updateMailCounts", item_mails);
    }
  }
}
