import { data } from '@/shared';

export default {
  namespaced: true,
  state: {
    pf: null
  },
  mutations: {
    updatePf(state, performer) {
      state.performer = performer;
    }
  },
  actions: {
    async getPfAction({ commit }) {
      const performer = await data.getDX("https://www.dxlive.com/event/thumbnailjsonUser/080124ran");
      commit("updatePf", performer);
      return performer
    }

  }
}
