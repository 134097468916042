import { API } from './config';
import axios from 'axios';
import router from '@/router';


const axios_instance = axios.create();

axios_instance.defaults.withCredentials = true
axios_instance.interceptors.request.use(config => {

  config.headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${ localStorage.csrf }`,
    'Refresh-Token': `${ localStorage.refresh }`
  }
  return config
});
const axios_del = axios.create();

axios_del.defaults.withCredentials = true
axios_del.interceptors.request.use(config => {
  config.headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${ localStorage.csrf }`
  }
  return config
});
// const axios_loginas = axios.create();

// axios_del.defaults.withCredentials = true
// axios_del.interceptors.request.use(config => {
//   config.headers = {
//     // 'Content-Type': 'application/json',
//     'X-API-KEY': 'vnisRCxLmDwgF7hTXC7JPEbqdthe'
//   }
//   return config
// });

const getDX = async function (url) {
  try {
    const response = await axios.get(url, {
      withCredentials: false
    });
    let data = parseList(response);
    return data.NGPerformers;
  } catch (error) {
    errorPage(error);
  }
}

const getJsonData = async function (url, obj) {
  let params = obj == undefined ? "" : obj
  try {
    const response = await axios.get(url, {
      params: params
    });
    let data = parseList(response);
    return data.data;
  } catch (error) {
    errorPage(error);
  }
}
const getData = async function (url, obj) {
  let params = obj == undefined ? "" : obj
  try {
    const response = await axios_instance.get(url, {
      params: params
    });
    let data = parseList(response);
    return data.data;
  } catch (error) {
    console.log(error)
    errorPage(error);
  }
}
// https://affstats.aff.hmsdev/api/sites?category=3&lang=en
const getSites = async function (url,obj) {
  let params = obj == undefined ? "" : obj
  try {
    const response = await axios_instance.get(url, {
      params: params
    });
    const sitedata = parseList(response);
    const recommended = sitedata.data.recommended;
    const allsites = sitedata.data.allsites;
    const items = recommended.concat(allsites);
    const maxComm = maxCommission(items);
    return maxComm;
  } catch (error) {
    errorPage(error);
  }
}
const getSite = async function (url, obj) {
  let params = obj == undefined ? "" : obj
  try {
    const response = await axios_instance.get(url, {
      params: params
    });
    let data = parseList(response);
    const item = maxCommission(data.data);
    return item[0];
  } catch (error) {
    errorPage(error);
  }
}
const maxCommission = function (items) {
  const flat = 'flat';
  const percent = 'percent';
  let newdata = [];
  for (var i in items) {
    let item = items[i];

    if (item.commission_rate != null) {
      
      if (item.commission_rate[1] != undefined && item.site_id != 18) {
        if (item.commission_rate[0]['max_rate'] > item.commission_rate[1]['max_rate']) {
          item.commission_type = percent
          item.commission_rate = item.commission_rate[0]['max_rate'];
        } else {
          item.commission_type = flat
          item.commission_rate = item.commission_rate[1]['max_rate'];
        }
        newdata.push(item);
      } else {
        if (item.commission_rate[0]['commission_type'] != 'Flat Rate') {
          item.commission_type = percent
          item.commission_rate = item.commission_rate[0]['max_rate'];
        } else {
          item.commission_type = flat
          item.commission_rate = item.commission_rate[0]['max_rate'];
        }
        newdata.push(item);
      }
    } else if ((item.site_id == 22) || (item.site_id == 99)) {
      item.commission_type = percent
      item.commission_rate = 5;
      newdata.push(item);
    } else {
      item.commission_type = '';
      newdata.push(item);
    }
  }
  return newdata;
}

const putData = async function (url, obj) {
  console.log(obj)
  let params = obj == undefined ? "" : obj
  const json = JSON.stringify(params);
  try {
    const response = await axios_instance.put(url, json);
    console.log(response)
    let data = parseList(response);
    return data.data;
  } catch (error) {
    console.log(params)
    console.log(error)
      errorPage(error);
  }
}
const putProfile = async function (url, obj) {
  console.log(obj)
  let params = obj == undefined ? "" : obj
  const json = JSON.stringify(params);
  try {
    const response = await axios_instance.put(url, json);
    console.log(response)
    let data = parseList(response);
    return data;
  } catch (error) {
    console.log(error)
    return error;
  }
}
const createInvoice = async function () {
  try {
    const response = await axios_instance.put(`${API}/invoice/request`);
    let data = parseList(response);
    console.log(data.data[0])
    return data.data[0];
  } catch (error) {
    return false
  }
}


const updateCancel = async function () {
  try {
    const response = await axios_instance.put(`${API}/account/profile/cancel`);
    let data = parseList(response);
    return data;
  } catch (error) {
    console.log(error.response)
    return false
  }
}

const postData = async function (url, obj) {
  let params = obj == undefined ? "" : obj
  const json = JSON.stringify(params);
  console.log('postData', json)
  try {
    const response = await axios_instance.post(url, json);
    let data = parseList(response);
    return data.data;
  } catch (error) {
    console.log(error.response)
    return false;
  }
}
// Send confirmation mail
// const sendEmail = async function (obj) {
//   let params = obj == undefined ? "" : obj
//   const json = JSON.stringify(params);
//   try {
//     const response = await axios_instance.post(`${API}/send_email`, json);
//     return true;
//   } catch (error) {
//     return false;
//   }
// }
const loginAsData = async function (url, params) { 
  let apikey = process.env.VUE_APP_ENV != "production" ? 'vnisRCxLmDwgF7hTXC7JPEbqd': 'bpYxLyEJquWREm65DHApB3Azd';
  console.log(process.env.VUE_APP_ENV, apikey)
  try {    
    
    const response = await axios.post(url + params, null, {
      headers: {
        'X-API-KEY': apikey
      }
    }); 
    return response.data;
  } catch (error) {
    return error;
  }
}
const authsData = async function (url, params) { 
  console.log(params)
  try {    
    const response = await axios.post(url + params); 
    return response.data;
  } catch (error) {
    return error;
  }
}
const requestCode = async function (url) { 
  try {    
    const response = await axios_del.post(url); 
    return response;
  } catch (error) {
    return error.response;
  }
}
const verifyCode = async function (url, obj) { 
  const param = obj != undefined ? obj : null
  try {    
    const response = await axios_del.post(url, param); 
    return response;
  } catch (error) {
    return error.response;
  }
}
const verifyLink = async function (url, obj) { 
  const param = obj != undefined ? obj : null
  try {    
    const response = await axios.post(url+'?'+param); 
    console.log('varifyLink')
    return response;
  } catch (error) {
    return error.response;
  }
}
const refreshData = async function (url) {
  console.log(url)
  try {    
    const response = await axios_instance.post(url);
    return response.data;
  } catch (error) {
    return error;
  }
}


const putPassword = async function (url, obj) {
  let params = obj == undefined ? "" : obj
  const json = JSON.stringify(params);
  console.log('json',json)
  try {
    const response = await axios_instance.put(url, json);
    console.log(response)
    return response;
  } catch (error) {
    return error;
  }
}

const logoutData = async function (url) { 
  console.log(url)
  try {   
    const response = await axios_del.delete(url)
    return response.data
  }catch (error) { 
    return error;
  } 
}
const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== 'object') {
    list = [];
  }
  return list;
};

const errorPage = function (error) {
  let eResponse = error.response,
    eStatus = eResponse.status;
  
  if (eResponse != undefined) {
    switch (eStatus) {
      case 401:
        router.push("/401");
        return Promise.resolve(eResponse);
      case 404:
        router.push("/404");
        return Promise.resolve(eResponse);
      case 422:
        router.push("/422");
        return Promise.resolve(eResponse);
      case 500:
        router.push("/500");
        return Promise.resolve(eResponse);
      case 504:
        router.push("/504");
        return Promise.resolve(eResponse);
    }
  } else {
    return Promise.reject(error);
  }
}
export const data = {
  getDX,
  getJsonData,
  getData,
  getSites,
  getSite,
  postData,
  putData,
  putProfile,
  createInvoice,
  updateCancel,
  // sendEmail,
  authsData,
  loginAsData,
  refreshData,
  logoutData,
  putPassword,
  requestCode,
  verifyCode,
  verifyLink
};