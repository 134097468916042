import { data, API } from '@/shared';

export default {
  namespaced: true,
  state: {    
    login: null,
    refresh: null,
    logout: null,
    forgotpassword: null,
    resetpassword: null
  },
  mutations: {
    updateLogIn(state, login) {
      state.login = login
    },
    updateRefresh(state, refresh) {
      state.refresh = refresh
    },
    updateLogOut(state, logout) {
      state.logout = logout
    },
    updateForgotPassword(state, password) {
      state.forgotpassword = password
    },
    updateResetPassword(state, password) {
      state.resetpassword = password
    }
  },
  actions: {
// POST /api/
//   parameters: userid & password
//   response body: access_token & refresh_token
    async logInAction({ commit }, params) {
      const login = await data.authsData(`${API}/login`, params);
      commit("updateLogIn", login);
      return login
    },
    async loginAsAction({ commit }, params) {
      const loginas = await data.loginAsData(`${API}/login_as`, params);
      commit("updateLogIn", loginas);
      return loginas
    },
// POST /api/refresh
//    headers:
//       Authorization: Bearer <access_token>
//       Refresh-Token: Bearer <refresh_token>

    async refreshAction({ commit }) {
      const refresh = await data.refreshData(`${API}/refresh`);
      commit("updateRefresh", refresh);
      return refresh
    },

// DELETE /api/logout
//    header:  
//       Authorization: Bearer <access_token>

    async logOutAction({ commit }) {
      const logout = await data.logoutData(`${API}/logout`);
      commit("updateLogOut", logout);
      return logout
    },
    forgotPasswordAction({ commit }, params) {
      const password = data.authsData(`${API}/passwords/forgot`, params);
      commit("updateForgotPassword", password);
      return password
    },
    resetPasswordAction({ commit }, params) {
      const password = data.putPassword(`${API}/passwords`, params);
      commit("updateResetPassword", password);
      return password
    },
  // POST '/email/request_code'
  //  Token required
  //  return: verification_code
    requestEmailCodeAction({ commit }) {
      const code = data.requestCode(`${API}/email/request_code`);
      return code
    }, 
    requestProfileCodeAction({ commit }) {
      const code = data.requestCode(`${API}/profile/request_code`);
      return code
    },
    requestCodeAction({ commit }, type) {
      console.log('requestCodeAction',type)
      const code = data.requestCode(`${API}/${type}/request_code`);
      return code
    }, 
  // POST '/email/verify_code'
  //  Token required
  //  parameter: verification_code=xxxxx{12char}
    verifyCodeAction({ commit }, params) {
      console.log('verifyCode', params)
      console.log(`${API}/${params.type}/verify_code`)
      const code = data.verifyCode(`${API}/${params.type}/verify_code`, params.params);
      return code
    },
  // POST '/email/update'
  //  Token required
  //  parameter: {"account":{"new_email": "test@wmstaff.com"}}
  //  return: verification_link_code and verification_link
     updateEmailAction({ commit }, param) {
      const response = data.postData(`${API}/email/update`, param);
      return response;
    }, 

  // GET '/email/verify'
  //   parameter: verification_link_code=xxxx{15char}
    verifyAction({ commit }, param) {
      console.log(param)
      const verify = data.verifyLink(`${API}/email/verify`, param);
      return verify
    }
  }
}
