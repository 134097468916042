import { data, API } from '@/shared'
//taikai : api/sales_report/daily?from=2021-05-09&type=unsubscribe&lang=ja
export default {
  namespaced: true,
  state: {
    sales: null,
    report: null,
    user: null
  },
  mutations: {
    updateSales(state, sales) {
      state.sales = sales
    },
    updateReport(state, report) {
      state.report = report
    },
    updateUser(state, user) {
      state.user = user
    },
    clearData(state) {
      state.sales= null
    }
  },
  actions: {
    async getSalesAction({ commit }, params) {
      const sales = await data.getData(`${API}/sales_report`, params)
      commit("updateSales", sales)
      return sales
    },
    async getReportAction({ commit }, params) {
      console.log('getReportAction',params)
      const report = await data.getData(`${API}/sales_report/daily`, params)
      commit("updateReport", report)
      console.log(report)
      return report
    },
    async getUserAction({ commit }, params) {
      const user = await data.getData(`${API}/user_report`, params)
      commit("updateUser", user)
      return user
    }
  }
}
